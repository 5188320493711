/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    em: "em",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "UK's First Sustainable Powerboat Racing Event in Windermere"), "\n", React.createElement(_components.h2, null, "Exciting News for Powerboat Racing Enthusiasts"), "\n", React.createElement(_components.p, null, "The UK's first sustainable powerboat racing event in Windermere is set to take place on April 20. The event is organized by the Windermere Motor Boat Racing Club (WMBRC) in partnership with Coryton. It will feature handicap powerboat racing, with all petrol-engine boats running on SUSTAIN Racing fuel supplied by Coryton. This event aims to promote sustainability in powerboat racing by using sustainable fuel."), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "Source: ", React.createElement(_components.a, {
    href: "https://www.thewestmorlandgazette.co.uk/news/24188529.uks-first-sustainable-powerboat-racing-event-windermere/"
  }, "The Westmorland Gazette"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
